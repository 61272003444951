import * as React from "react"
import { Link, graphql } from "gatsby"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

// components
import Layout from '../components/layout'

// icons
import { IconArrowRight } from '../components/icons';

// seo
const seo = {
  title: 'seo.about.title',
  description: 'seo.about.description'
}

const SobrePage = () => {
  const { t } = useTranslation();

  const board = [
    {
      title: t('board.first.title'),
      position: t('board.first.position'),
      description: t('board.first.description')
    },
    {
      title: t('board.second.title'),
      position: t('board.second.position'),
      description: t('board.second.description')
    },
    {
      title: t('board.third.title'),
      position: t('board.third.position'),
      description: t('board.third.description')
    },
    {
      title: t('board.fourth.title'),
      position: t('board.fourth.position'),
      description: t('board.fourth.description')
    },
  ]

  return (
    <>
      <Layout seo={seo}>
        <section className="pageHeader pageHeader--full pageHeader--light pageHeader--imageOffset">
          <div className="container">
            <h1><Trans i18nKey="seo.about.title" /></h1>
            <h2 className="title title--display">
              <Trans i18nKey={'title.primary'}/>
            </h2>

            <article className="article">
              <p className="lead">
                {t('lead.primary')}
              </p>

              <p className="lead">
                {t('lead.secondary')}
              </p>
            </article>
          </div>
          
          <br></br>
        <br></br>
          {/*}
          <div className="imageOffset">
            <img alt="" className="hero__image image image--rounded image--shadow" src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=540&h=700&q=80"/>
  </div> */}
          <hr className="divider"/>
        </section>
        <section className="section">
          <div className="container">
            <blockquote className="quote">
              <span>
                <br />
                <Trans i18nKey="quote.message" />
                <Trans
                  i18nKey="quote.by" 
                  components={{ small: <small />}}
                />
              </span>
            </blockquote>
          </div>
        </section>
        


        <section className="section">
          <div className="container grid lg:grid-cols-2">
            <div className="flex items-center bg-gray-200 p-md lg:p-lg">
              <h2 className="title title--display">
                <Trans i18nKey="title.secondary" />
              </h2>
            </div>

            <div className="image image--tint image--secondary">
              <img alt="" src="https://images.unsplash.com/photo-1538688423619-a81d3f23454b?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&h=934&q=80"/>
            </div>

            <div className="image image--tint image--secondary">
              <img alt="" src="https://images.unsplash.com/photo-1543269865-4430f94492b9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&h=934&q=80"/>
            </div>

            <div className="flex items-center bg-gray-200 p-md lg:px-lg lg:py-0">
              <p className="lead">
                <Trans i18nKey="lead.tertiary" />
              </p>
            </div>
          </div>
        </section>

        <hr className="divider"/>

        <section className="section">
          <div className="container">
            <div className="card card--horizontal card--light card--rounded">
              <img alt="" className="card__image image image--pb" src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=540&h=600&q=80"/>

              <div className="card__body">
                <h2 className="title title--display">
                  <Trans i18nKey="title.tertiary" />
                </h2>
                <p><Trans i18nKey="lead.quaternary" /></p>
                <Link className="card__action button button--pink button--nude" to="/carreiras">
                  <span><Trans i18nKey="more" /></span>
                  <IconArrowRight className="icon"/>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="hidden">
          {/* Ocultando seção por enquanto */}
          <hr className="divider"/>

          <section className="section">
            <h2 className="text-center title title--display"><Trans i18nKey='board.title' /></h2>
      
            <div className="container grid items-start grid-cols-2 lg:grid-cols-4 gap-md mt-lg">
              {board.map((person, index) => {
                return (
                  <div key={index} className="card">
                    <img alt={person.title} className="mb-5 card__image image image--pb image--rounded" src="https://www.lispolis.pt/wp-content/uploads/2020/05/profileavatar.png"/>
                    <h3 className="mb-0">{person.title}</h3>
                    <h4 className="mb-5 text-secondary">{person.position}</h4>

                    <p>
                      {person.description}
                    </p>
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default SobrePage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["sobre", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;